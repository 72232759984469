<template>
  <div class="add-company__wrapper">
    <button class="add-company__button" @click="onAddCompanyClick">
      <a-icon
        original
        :icon="ICON.ADD_COMPANY_RECT_GREEN"
        :width="35"
        :height="35"
        title="Add Company"
        :title-width="50"
      />
    </button>
  </div>
</template>

<script>
import mixins from '@/utils/mixins'
import { FM_ROUTE } from 'enums/routes'
import AIcon, { ICON } from 'shared/AIcon'
import { PENDING_ACTION } from 'enums/pending-actions'

export default {
  name: 'AAddCompanyButton',
  components: { AIcon },
  mixins: [mixins.pendingAuthActions, mixins.urlGenerators],
  data() {
    return {
      ICON
    }
  },
  methods: {
    onAddCompanyClick() {
      this.$_pendingAuthActions_handleAction({
        actionType: PENDING_ACTION.ADD_COMPANY,
        authorizedUserLocation: this.generateFmUrl(
          FM_ROUTE.PERSONAL_PROFILE_ADD_COMPANY
        )
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.add-company__wrapper {
  position: absolute;
  z-index: 1;
  top: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  font-weight: normal;
  align-items: center;

  /deep/ .a-icon__description {
    color: $c--upload-button;
    pointer-events: none;
    cursor: pointer;
  }
}
</style>
