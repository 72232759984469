<template>
  <a-sticky class="contact-widget__sticky">
    <ul class="contact-widget__list">
      <li
        v-if="isAddCompanyVisible"
        class="contact-widget__list-item add-company"
      >
        <a-add-company-button
          v-if="displayWidgetButtons"
          class="contact-widget__list-item-button"
          id="contact-widget__add-company-button"
        />
      </li>
      <li class="contact-widget__list-item">
        <button
          v-if="displayWidgetButtons"
          :id="$options.consts.ACTIVATOR_ID.CONTACT_US_WIDGET_BUTTON"
          aria-label="Contact Us"
          class="contact-widget__list-item-button"
          @click="showContactUsModal"
        >
          <a-icon
            :icon="$options.consts.ICON.ENVELOPE_RECT_BLUE"
            :color="$options.consts.ICON_COLOR.BLUE"
            :disabled="isDisabled"
            :width="35"
            :height="35"
            original
          />
        </button>
        <label
          for="contact-widget__contact-us-button"
          class="contact-widget__list-item-button-label"
        >
          Contact us
        </label>
        <div v-if="isLazyLoaded">
          <a-modal-widget
            v-model="showContactUs"
            :activator-selector="contactUsActivatorSelector"
            :parent-classes-to-prevent-clickaway="
              $options.consts.CLASSES_TO_PREVENT_WIDGET_CLOSE_ON_BLUR
            "
            success-message-type="contact-us"
            :scroll-top="-140"
            :links-top="110"
            :slot-selector="$options.consts.SELECTOR.CONTACT_US_FORM"
            :error-message-on-slot-load-failure="
              $options.consts.FORM_LOAD_ERROR_MESSAGE.CONTACT_US
            "
            v-bind="widgetProps"
            @close-modal="resetActivators"
          >
            <a-contact-us-form
              slot-scope="{ closeModal }"
              :closeOnSubmit="closeModal"
              :show-success-message-in-modal="false"
              :form-id="$options.consts.FORM_ID.CONTACT_US_WIDGET"
              class="contact-widget__form"
            />
          </a-modal-widget>
        </div>
      </li>
      <li class="contact-widget__list-item">
        <button
          v-if="displayWidgetButtons"
          :id="$options.consts.ACTIVATOR_ID.NEWSLETTER_WIDGET_BUTTON"
          aria-label="Newsletter"
          class="contact-widget__list-item-button"
          :data-ref="$options.consts.REFS.NEWSLETTER_BUTTON"
          @click="showNewsletterModal"
        >
          <a-icon
            :icon="$options.consts.ICON.NEWSLETTER_RECT_BLUE"
            :color="$options.consts.ICON_COLOR.BLUE"
            :width="35"
            :height="35"
            :disabled="isDisabled"
            original
          />
        </button>
        <label
          for="contact-widget__newsletter-button"
          class="contact-widget__list-item-button-label"
        >
          Newsletter
        </label>
        <div v-if="isLazyLoaded">
          <a-modal-widget
            v-model="showNewsletter"
            :activator-selector="newsletterActivatorSelector"
            :parent-classes-to-prevent-clickaway="
              $options.consts.CLASSES_TO_PREVENT_WIDGET_CLOSE_ON_BLUR
            "
            success-message-type="newsletter"
            :scroll-top="-190"
            :links-top="60"
            :slot-selector="$options.consts.SELECTOR.NEWSLETTER_FORM"
            :error-message-on-slot-load-failure="
              $options.consts.FORM_LOAD_ERROR_MESSAGE.NEWSLETTER
            "
            v-bind="widgetProps"
            @close-modal="resetActivators"
          >
            <a-newsletter-form
              slot-scope="{ closeModal }"
              :close-on-submit="closeModal"
              :form-id="$options.consts.FORM_ID.NEWSLETTER_WIDGET"
              class="newsletter-widget__form"
            />
          </a-modal-widget>
        </div>
      </li>
      <li
        class="contact-widget__list-item socials"
        v-if="showContactUs || showNewsletter"
      >
        <a-icon-list
          :icons="icons"
          :layout="$options.consts.ICON_LIST_LAYOUT.VERTICAL"
          :icon-color="$options.consts.ICON_COLOR.BLUE"
        />
      </li>
    </ul>
  </a-sticky>
</template>

<script>
import { propValidator, PROP_TYPES } from '@/utils/validators'
import AIcon, { ICON, ICON_COLOR } from 'shared/AIcon'
import mixins from '@/utils/mixins'
import { ROUTE_NAME } from 'enums/routes'
import AAddCompanyButton from 'shared/AAddCompanyButton'
import { FORM_ID } from 'enums/form-id'
import { REFS } from 'enums/external-refs'
import { hydrationHelpers } from '@/utils/mixins/hydrationHelpers'
import ASticky from 'shared/ASticky'
import { ICON_LIST_LAYOUT } from 'shared/AIconList/enums'
import { WIDGETS_WRAPPER_CLASS } from '@/components/_layout/ALayoutWidgets/enums'
import {
  OPEN_LAYOUT_WIDGET_BUS_NAME,
  CLOSE_LAYOUT_WIDGETS_BUS_NAME
} from 'shared/AWidgets/enums'
import {
  ACTIVATOR_SELECTOR_BY_ACTIVATOR_ID,
  ACTIVATOR_ID
} from 'enums/activator-id'

export const WIDGET = {
  CONTACT_US: 'contact-us',
  NEWSLETTER: 'newsletter'
}

export default {
  name: 'AWidgets',
  mixins: [mixins.urlGenerators, hydrationHelpers],
  components: {
    AIconList: () => import('shared/AIconList'),
    AAddCompanyButton,
    AModalWidget: () => import('modals/AModalWidget'),
    AContactUsForm: () => import('shared/AContactUsForm'),
    ANewsletterForm: () => import('shared/ANewsletterForm'),
    AIcon,
    ASticky
  },
  props: {
    displayWidgetButtons: propValidator([PROP_TYPES.BOOLEAN], false, true),
    isDisabled: propValidator([PROP_TYPES.BOOLEAN], false, false)
  },
  consts: {
    FORM_ID,
    REFS,
    ICON_LIST_LAYOUT,
    ICON,
    SELECTOR: {
      NEWSLETTER_FORM: `.contact-widget__list #${FORM_ID.NEWSLETTER_WIDGET}`,
      CONTACT_US_FORM: `.contact-widget__list #${FORM_ID.CONTACT_US_WIDGET}`,
      NEWSLETTER_BUTTON:
        ACTIVATOR_SELECTOR_BY_ACTIVATOR_ID[
          ACTIVATOR_ID.NEWSLETTER_WIDGET_BUTTON
        ],
      CONTACT_US_BUTTON:
        ACTIVATOR_SELECTOR_BY_ACTIVATOR_ID[
          ACTIVATOR_ID.CONTACT_US_WIDGET_BUTTON
        ]
    },
    CLASSES_TO_PREVENT_WIDGET_CLOSE_ON_BLUR: [WIDGETS_WRAPPER_CLASS],
    ACTIVATOR_ID,
    ICON_COLOR,
    FORM_LOAD_ERROR_MESSAGE: {
      NEWSLETTER: 'Error loading a Newsletter Form. Please try to reopen it.',
      CONTACT_US: 'Error loading a Contact Us Form. Please try to reopen it.'
    }
  },
  data() {
    return {
      contactUsActivatorSelector: this.$options.consts.SELECTOR
        .CONTACT_US_BUTTON,
      newsletterActivatorSelector: this.$options.consts.SELECTOR
        .NEWSLETTER_BUTTON,
      isLazyLoaded: false,
      showContactUs: false,
      showNewsletter: false,
      openWidgetHandlers: {
        [WIDGET.CONTACT_US]: this.showContactUsModal,
        [WIDGET.NEWSLETTER]: this.showNewsletterModal
      },
      icons: [
        {
          url: this.$env.SOCIAL_LINK_TWITTER_FL,
          icon: ICON.TWITTER_ROUND_BLACK,
          openInNewTab: true,
          ariaLabel: 'Twitter'
        },
        {
          url: this.$env.SOCIAL_LINK_TELEGRAM_FL,
          icon: ICON.TELEGRAM_ROUND_BLACK,
          openInNewTab: true,
          ariaLabel: 'Telegram'
        },
        {
          url: this.$env.SOCIAL_LINK_FACEBOOK_FL,
          icon: ICON.FACEBOOK_ROUND_BLACK,
          openInNewTab: true,
          ariaLabel: 'Facebook'
        },
        {
          url: this.$env.SOCIAL_LINK_YOUTUBE_FL,
          icon: ICON.YOUTUBE_ROUND_BLACK,
          openInNewTab: true,
          ariaLabel: 'YouTube'
        },
        {
          url: this.$env.SOCIAL_LINK_LINKEDIN_FL,
          icon: ICON.LINKEDIN_ROUND_BLACK,
          openInNewTab: true,
          ariaLabel: 'LinkedIn'
        }
      ]
    }
  },
  computed: {
    widgetProps() {
      return {
        width: 560,
        right: this.$_hydrationHelpers_getValueByBreakpoint({
          [this.$breakpoint.aboveDesktopXl]: 35,
          [this.$breakpoint.desktopLg]: 43
        })
      }
    },
    isAddCompanyVisible() {
      return this.$route.name === ROUTE_NAME.FOREX_BROKERS
    }
  },
  methods: {
    resetActivators() {
      const {
        CONTACT_US_BUTTON,
        NEWSLETTER_BUTTON
      } = this.$options.consts.SELECTOR

      this.contactUsActivatorSelector = CONTACT_US_BUTTON
      this.newsletterActivatorSelector = NEWSLETTER_BUTTON
    },
    closeWidgets() {
      this.showContactUs = false
      this.showNewsletter = false
    },
    showContactUsModal({
      activatorSelector = this.contactUsActivatorSelector
    } = {}) {
      if (this.isDisabled) return

      this.closeWidgets()

      this.showContactUs = true
      this.isLazyLoaded = true
      this.contactUsActivatorSelector = activatorSelector
    },
    showNewsletterModal({
      activatorSelector = this.newsletterActivatorSelector
    } = {}) {
      if (this.isDisabled) return

      this.closeWidgets()

      this.showNewsletter = true
      this.isLazyLoaded = true
      this.newsletterActivatorSelector = activatorSelector
    }
  },
  mounted() {
    this.$bus.$on(OPEN_LAYOUT_WIDGET_BUS_NAME, ({ name, activatorSelector }) =>
      this.openWidgetHandlers[name]({ activatorSelector })
    )

    this.$bus.$on(CLOSE_LAYOUT_WIDGETS_BUS_NAME, this.closeWidgets)
  },
  beforeDestroy() {
    this.$bus.$off(OPEN_LAYOUT_WIDGET_BUS_NAME)
    this.$bus.$off(CLOSE_LAYOUT_WIDGETS_BUS_NAME)
  }
}
</script>

<style lang="scss" scoped>
.contact-widget__sticky {
  width: 35px;
  top: 140px;
  margin: 0 auto 50px;
  pointer-events: all;

  .contact-widget__list {
    margin: 0;
  }

  .contact-widget__list-item {
    position: relative;
    list-style-type: none;
    font-size: 0;

    &.add-company {
      margin-bottom: 20px;
      width: 35px;
    }

    &.socials {
      margin-top: 10px;
    }
  }

  .contact-widget__list-item-button-label {
    position: absolute;
    right: 100%;
    top: 50%;
    transform: translate(50%, -50%);
    opacity: 0;
    font-family: $font-sans;
    font-size: 13px;
    line-height: 1.3;
    padding: 1px 5px;
    color: #626262;
    background: white;
    border: 1px solid;
    border-right: none;
    word-break: initial;
    white-space: nowrap;
    transition: transform 0.2s, opacity 0.2s;
    pointer-events: none;
  }

  .contact-widget__form {
    padding: 60px 40px 40px;

    @include mobile {
      padding: 44px 15px 15px;
    }

    /deep/ .form__submit-button {
      width: 100%;
    }
  }

  .newsletter-widget__form {
    padding: 60px 40px 40px;

    @include mobile {
      padding: 40px 20px 15px;
    }
  }

  .contact-widget__list-item-button {
    position: relative;
    z-index: 1;
    background: white;
    font-size: 0;
    margin-bottom: 15px;

    & * {
      cursor: pointer;
    }

    &:hover {
      .widget-button__icon {
        fill: black;
      }

      ~ .contact-widget__list-item-button-label {
        transform: translate(0, -50%);
        opacity: 1;
      }
    }
  }
}
</style>
<style lang="scss">
#__nuxt {
  .global-wrapper.preview-mode .contact-widget__sticky {
    top: calc(140px + #{$size-preview-panel});
  }
}
</style>
